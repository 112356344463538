
          @import "src/config/variables.scss";
        
.rowTitle {
  font-weight: 600;
}

.overdueStatus {
  color: $red;
}
