
          @import "src/config/variables.scss";
        
.activeStatus {
  color: $green;
}

.reactiveStatus {
  color: $red;
}

.inactiveStatus {
  color: $gray;
}

.deactivated {
  color: $red;
}
