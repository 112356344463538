
          @import "src/config/variables.scss";
        
.roleSelect {
  min-width: 100%;
}

.fileError {
  color: $red;
}

.deleteFileIcon {
  color: $red;
  cursor: pointer;
  :hover {
    color: $sky-blue;
  }
}

.fileError {
  color: $red;
}

.deleteFileIcon {
  color: $red;
  cursor: pointer;
  :hover {
    color: $sky-blue;
  }
}

.description {
  width: 100%;
}

.error {
  color: $red;
}

.title {
  font-size: 24px;
  font-weight: 400;
}

.selectPopup {
  position: fixed;
}
