
          @import "src/config/variables.scss";
        
.title {
  margin-left: 10px;
}

.container {
  margin-left: 12px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  color: $dirty-white;
  :hover {
    color: $gray;
  }
}

.selectedItem {
  color: $green;
}
