
          @import "src/config/variables.scss";
        
.projectName {
  font-size: 28px;
  font-weight: 400;
}

.tabName {
  font-size: 21px;
}