
          @import "src/config/variables.scss";
        
.content {
  background-color: $white;
  width: 100%;
  transition: 0.3s;
}

.contentWithSider {
  margin-left: 300px;
  transition: 0.3s;
}

.sidebarMenuContainer {
  margin-top: 24px;
}

.sidebarCollapseContainer {
  text-align: end;
  color: $black;
}

.sidebarCollapseIcon {
  font-size: 24px;
}

.collapseIcon {
  margin-left: 12px;
  font-size: 28px;
}

.header {
  background-color: $dirty-white;
  margin-bottom: 12px;
  padding: 0;
  max-width: 100%;
}

.headerName {
  font-size: 28px;
  font-weight: 500;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 12px;
}

.sider {
  min-height: 100vh;
  background-color: $deep-blue;
  overflow: scroll;
  position: fixed;
  transition: 0.3s;
}
