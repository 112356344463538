
          @import "src/config/variables.scss";
        
.projectName {
  font-size: 28px;
  font-weight: 400;
}

.tabName {
  font-size: 21px;
}

.HSETrainingComplete {
  padding: 2px 8px;
  border: 2px solid red;
  color: red;
  font-size: 14px;
  font-weight: 500;
}

.rowCenter {
  display: flex;
  align-items: center;
  gap: 16px;
}
