
          @import "src/config/variables.scss";
        
.selectedItemInList {
  border: solid 1px $gray;
  border-radius: 3px;
  background-color: $dirty-white;
}

.removeTaskIcon {
  color: $red;
  :hover {
    opacity: 60%;
  }
}
