
          @import "src/config/variables.scss";
        
.avatar {
  height: 42px;
  width: 42px;
  object-fit: cover;
}

.defaultAvatarIcon {
  height: 42px;
  width: 42px;
  background-color: $dirty-white;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
