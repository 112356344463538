
          @import "src/config/variables.scss";
        
.hiddenRow {
  background-color: $light-gray;
  :global {
    // TODO should be interrupted without !important
    .ant-table-cell-row-hover {
      background-color: $light-gray !important;
    }
  }
}
