
          @import "src/config/variables.scss";
        
.avatar {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 10px;
  background-color: $white;
}

.name {
  color: $white;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.badgeIcon {
  color: $white;
  font-size: 20px;
}

.defaultUserIcon {
  font-size: 30px;
  color: $white;
}

.logo {
  width: 150px;
}
