
          @import "src/config/variables.scss";
        
.activeStatus {
  color: $green;
}

.reactiveStatus {
  color: $red;
}

.deactivateSwitcherContainer {
  :global {
    .ant-switch-checked {
      background-color: $red;
    }
  }
}

.selectPopup {
  position: fixed;
}

.select {
  width: 100%;
}
