
          @import "src/config/variables.scss";
        
.container {
  color: $white;
  height: 50px;
}

.logoutButton {
  color: $white;
}
