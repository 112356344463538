
          @import "src/config/variables.scss";
        
.userName {
  font-weight: 500;
  font-size: 20px;
}

.userPosition {
  font-size: 16px;
  font-weight: 500;
}

.activeStatus {
  color: $green;
}

.reactiveStatus {
  color: $red;
}

.inactiveStatus {
  color: $gray;
}

.email {
  color: $green;
}
