
          @import "src/config/variables.scss";
        
.doneStatus {
  color: $green;
}

.archivedStatus {
  color: $gray;
}

.deleteIcon {
  color: $red;
  &:hover {
    opacity: 60%;
  }
}
