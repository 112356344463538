
          @import "src/config/variables.scss";
        
.title {
  font-weight: 300;
  font-size: 32px;
  color: $dirty-white;
}

.container {
  height: 100vh;
  background-color: $deep-blue;
  text-align: end;
}

.signinFormContainer {
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.logo {
  margin-top: 24px;
  margin-right: 24px;
  width: 15vw;
}

.signinButton {
  color: $dirty-white;
  background-color: $blue-clay;
  border: none;
  &:focus {
    background-color: $blue-clay;
  }
}

.signinButton:hover {
  background-color: $blue-clay;
}

.andSymbol {
  color: $green;
}

.appName {
  font-weight: 500;
  font-size: 4vw;
  color: $dirty-white;
  :global {
    .ant-col {
      &::first-letter {
        margin-right: -5px;
        font-size: 5vw;
        color: $green;
      }
    }
  }
}

.microsoftIcon {
  height: 24px;
}
