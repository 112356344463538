
          @import "src/config/variables.scss";
        
.select {
  width: 100%;
}

.submitButton {
  text-align: end;
}

.title {
  font-size: 24px;
  font-weight: 400;
}

.selectPopup {
  position: fixed;
}
