
          @import "src/config/variables.scss";
        
.positiveStatus {
  color: $green;
}

.negativeStatus {
  color: $red;
}

.archivedStatus {
  color: $gray;
}

.downloadIcon {
  font-size: 18px;
}

.deleteIcon {
  color: $red;
  &:hover {
    opacity: 60%;
  }
}
