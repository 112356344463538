
          @import "src/config/variables.scss";
        
.done {
  color: $green;
  font-size: 21px;
}

.overdue {
  color: $red;
  font-size: 21px;
}

.titleText {
  font-size: 21px;
}
