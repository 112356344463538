
          @import "src/config/variables.scss";
        
.search {
  width: 100%;
}

.disabledFilter {
  color: $gray;
}

.dropdownContent {
  background: #fff;
  box-shadow: 0px 0px 5px $gray;
  text-align: end;
  :global {
    .ant-dropdown-menu {
      box-shadow: none;
    }
  }
}
