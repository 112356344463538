
          @import "src/config/variables.scss";
        
.popover {
  width: 200px;
}

.a {
  color: #69be28;
  cursor: pointer;
}

.label {
  color: white;
  display: flex;
  gap: 8px;
}
