
          @import "src/config/variables.scss";
        
.select {
  width: 100%;
}

.inputNumber {
  width: 100%;
}

.inputNumber {
  width: 100%;
}

.selectPopup {
  position: fixed;
}
