
          @import "src/config/variables.scss";
        
.rowTitle {
  font-weight: 600;
}

.doneStatus {
  color: $green;
}

.archivedStatus {
  color: $gray;
}
