
          @import "src/config/variables.scss";
        
.expandRowName {
  font-weight: 450;
}

.expandRowData {
  font-weight: 200;
}
