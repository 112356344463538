
          @import "src/config/variables.scss";
        
.sliderFilterDropdown {
  height: 55px;
  width: 300px;
  display: inline-block;
  vertical-align: middle;
}

.slider {
  margin-left: auto;
  margin-right: auto;
  width: 250px;
}

.grayFilter {
  color: $gray;
}

.greenFilter {
  color: $green;
}

.orangeFilter {
  color: $warningYellow;
}

.redFilter {
  color: $red;
}
