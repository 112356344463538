
          @import "src/config/variables.scss";
        
.done {
  color: $green;
  font-size: 21px;
}

.overdue {
  color: $red;
  font-size: 21px;
}

.titleText {
  font-size: 21px;
}

.spinner {
  display: table-cell;
  height: 80vh;
  width: 100vw;
  vertical-align: middle;
  align-items: center;
}

.helpLink {
  margin-left: 6px;
}
