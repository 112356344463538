
          @import "src/config/variables.scss";
        
.title {
  font-size: 24px;
}

.text {
  font-size: 16px;
}

.downloadButtonText {
  margin-left: 6px;
}
