
          @import "src/config/variables.scss";
        
.overdueCertificate {
  color: $red;
}

.userName {
  font-weight: 400;
  font-size: 32px;
}
