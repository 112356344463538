
          @import "src/config/variables.scss";
        
.avatar {
  border-radius: 0px;
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.mobileAvatar {
  border-radius: 0px;
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.defaultUserImageContainer {
  width: 150px;
  height: 150px;
  background-color: $dirty-white;
  border-radius: 0px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.defaultMobileUserImageContainer {
  min-width: 150px;
  height: 150px;
  background-color: $dirty-white;
  border-radius: 0px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.defaultUserIcon {
  font-size: 100px;
}
