
          @import "src/config/variables.scss";
        
.checkIcon {
  font-size: 16px;
  color: $green;
}

.overdue {
  font-size: 16px;
  color: $red;
}

.archived {
  font-size: 16px;
  color: $gray;
}

.fileIcon {
  font-size: 24px;
  cursor: pointer;
}
