
          @import "src/config/variables.scss";
        
.activeStatus {
  color: $green;
}

.reactiveStatus {
  color: $red;
}

.inactiveStatus {
  color: $gray;
}

.title {
  font-size: 26px;
  font-weight: 400;
}
