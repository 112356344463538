
          @import "src/config/variables.scss";
        
.item {
  height: 45px;
  display: flex;
  display: inline-flex;
  align-items: center;
}

.selectedItem {
  background-color: $blue-clay;
  border-radius: 4px;
}

.menuName {
  color: $white;
  font-size: 18px;
  font-weight: 400;
}