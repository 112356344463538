
          @import "src/config/variables.scss";
        
.positiveStatus {
  color: $green;
}

.negativeStatus {
  color: $red;
}

.archivedStatus {
  color: $gray;
}
