
          @import "src/config/variables.scss";
        
.downloadIcon {
  font-size: 18px;
}

.container {
  font-size: 16px;
  font-weight: 300;
}

.deleteIcon {
  color: $red;
  &:hover {
    opacity: 60%;
  }
}
