
          @import "src/config/variables.scss";
        
.tagContainer {
  margin-right: 3px;
}

.tasksSelect {
  width: 100%;
}

.modalFilter {
  min-width: 1000px;
}
