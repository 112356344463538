
          @import "src/config/variables.scss";
        
.filter {
  font-weight: bold;
  cursor: pointer;
}

.filterHint {
  color: $warningYellow;
}
