$gray: #afafaf;
$light-gray: #dddddd;
$dirty-white: #eff2f4;
$white: #fff;
$green: #69be28;
$green-highlight: #69be2842;
$sky-blue: #0c7fda;
$blue-clay: #444444;
$deep-blue: #111112;
$red: #dd0000;
$ghost-red: rgb(255, 230, 230);
$warningYellow: #ffa41b;
$black: #000000;
