
          @import "src/config/variables.scss";
        
.avatar {
  height: 46px;
  width: 57px;
  object-fit: cover;
  border-radius: 10px;
  background-color: $white;
}

.name {
  color: $white;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-overflow: ellipsis;
}

.badgeIcon {
  color: $white;
  font-size: 20px;
}

.selected {
  color: $green;
}
