
          @import "src/config/variables.scss";
        
.tasksList {
  height: 500px;
  overflow-y: scroll;
}

.grid {
  text-align: "center";
  cursor: pointer;
}

.taskNameTooltip {
  text-overflow: ellipsis;
}

.selectedGrid {
  background-color: $green-highlight;
}

.filterForm {
  margin-top: 24px;
}
