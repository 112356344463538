
          @import "src/config/variables.scss";
        
.roleSelect {
  width: 100%;
}

.deleteFileIcon {
  color: $red;
  cursor: pointer;
  :hover {
    color: $sky-blue;
  }
}

.fileError {
  color: $red;
}

.error {
  color: $red;
}
