
          @import "src/config/variables.scss";
        
.notificationContainer {
  border: 1px solid $dirty-white;
  border-radius: 10px;
  width: 100%;
}

.notification {
  margin: 12px;
}

.image {
  border-radius: 6px;
  border-radius: 6px;
}

.title {
  font-size: 20px;
}

.description {
  font-size: 16px;
  font-weight: 200;
}

.time {
  color: $gray;
  font-size: 12px;
  height: 12px;
  margin-right: 12px;
  text-align: end;
}

.userIcon {
  font-size: 24px;
  width: 40px;
  height: 40px;
  background-color: $dirty-white;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  border-radius: 6px;
}

.userImage {
  margin-left: 12px;
}
