
          @import "src/config/variables.scss";
        
.title {
  font-size: 18px;
  font-weight: 400;
}

.select {
  width: 100%;
}
