
          @import "src/config/variables.scss";
        
.selectTrainings {
  width: 100%;
}

.title {
  font-size: 24px;
  font-weight: 400;
}
